
import Swal from 'sweetalert2'
// const cfg = require(`~/configs/${process.env.NODE_ENV}.json`)
export default {
  props: {
    orderID: {
      type: [Number, String],
      required: true,
      default: null
    },
    paymentSelected: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      selected: null,
      order: null
    }
  },
  watch: {
    selected: {
      handler (newVal) {
        this.$emit('update:paymentSelected', newVal)
      },
      deep: true
    }
  },
  methods: {
    async paid () {
      try {
        const { success, order } = await this.$axios.$get(`order/${this.orderID}/paid-debit-direct`)
        if (success) {
          this.orderData = order
          this.$emit('paid')
          // Swal.fire({
          //   title: 'บันทึก CC-Email สำเร็จ',
          //   icon: 'success'
          // })
        }
      } catch (errors) {
        if (errors.response.status === 422) {
          let errorText = ''
          Object.values(errors.response.data.errors).forEach((arr, i) => {
            arr.forEach((err, index) => {
              errorText += err
            })
          })

          Swal.fire({
            title: 'เกิดข้อผิดพลาด',
            text: errorText,
            icon: 'error'
          })
        }
      }
    }
  }
}
