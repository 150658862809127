
  import { mapMutations, mapState } from 'vuex'
  export default {
    validate ({ params }) {
      // Must be a number
      return /^\d+$/.test(params.id)
    },
    async asyncData ({ params, $axios, $cookies }) {
      try {
        const result = await $axios.$get(`orders/${params.id}`)
        let resultMapping = null
        if (result.data && typeof result.data.products !== 'undefined') {
          resultMapping = await $axios.$get('mapping_inputs', {
            params: { mapping_pattern_id: result.data.products[0].mapping_input_id }
          })
        }
        return {
          orderID: params.id,
          totalResult: result.data,
          packagingTypeId: (resultMapping != null && result.data.products[0].mapping_input_id) ? resultMapping.data.mappingInput.packaging_type_id : 4,
          orderCode: params.order_code,
          orderDetail: result.data,
          mappingInputObject: (resultMapping != null) ? resultMapping.data : null
        }
      } catch (error) {
        console.log('error credit-card-finished', error.message)
      }
    },
    data () {
      return {
      }
    },
    computed: {
      ...mapState(['userData'])
    },
    mounted () {
    },
    methods: {
      ...mapMutations(['showLoading', 'hideLoading'])
    }
  }
  