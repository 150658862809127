
import Swal from 'sweetalert2'
import { mapMutations } from 'vuex'
import ModalOTP from './ModalOTP.vue'
export default {
  components: {
    ModalOTP
  },
  props: {
  },
  data () {
    return {
      msg: '',
      header: 'ยืนยันตัวตน',
      show: false,
      phone: '',
      code: '',
      verify: 1,
      verifyCode: '',
      register: false,
      login: false,
      email: '',
      otpOption: 'phone',
      showOtpOption: false,
      userId: null,
      confirmed: false
    }
  },
  mounted () {
    this.$refs.refPhone.focus()
    // this.$nextTick(() => {
    //   console.log('Nexttick >>> 1')
    // })
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert', 'isNumber']),
    async otp () {
      // await this.$refs.modalOTP.showModal()
      await this.requestOtp()
    },
    isNumber (evt) {
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async requestOtp () {
      this.showLoading()
      const url = 'otp-login'

      try {
        const result = await this.$axios.$post(url, { otpOption: this.otpOption, phone: '0' + this.phone })
        this.userId = result.data.id
        if (result.status === true && this.userId) {
          this.$refs.modalOTP.showModal()
        } else if (result.status === false) {
          Swal.fire({
            icon: 'warning',
            title: 'แจ้งเตือน',
            text: result.message
          })
        } else {
          Swal.fire({
            title: 'ไม่สามารถทำรายการได้'
          })
        }
      } catch (e) {
        console.log('errror', e.message)
      }

      this.hideLoading()
      return false
    },
    async verifyOTP (otp) {
      this.showLoading()
      const url = 'verify-otp-login'
      try {
        const result = await this.$axios.$post(url, {
          phone: '0' + this.phone,
          code: otp,
          email: this.email,
          otpOption: this.otpOption,
          accept_term: 1
        })
        this.hideLoading()
        // console.log('confirmOTP', result)
        if (result.status === true) {
          await this.$refs.modalOTP.hideModal()
          await this.$store.dispatch('authenticateSmsUser', result)
          await this.complete()
          // this.$router.push('/auth/login-via-phone')
          return true
        }
      } catch (errors) {
        if (errors.response.status === 422) {
          let errorText = ''
          Object.values(errors.response.data.errors).forEach((arr, i) => {
            arr.forEach((err, index) => {
              errorText += err
            })
          })

          Swal.fire({
            title: 'เกิดข้อผิดพลาด',
            text: errorText,
            icon: 'error'
          })
        } else {
          Swal.fire({
            title: 'เกิดข้อผิดพลาด',
            icon: 'error',
            message: errors.message
          })
        }
        console.log('error', errors.message)
      }
      this.hideLoading()
    },
    complete () {
      if (localStorage.getItem('click-login-from-page') === 'customize-product-design-id') {
        this.showLoading()
        const routeOpt = { name: localStorage.getItem('click-login-from-page') }
        const paramsJson = JSON.parse(localStorage.getItem('click-login-from-page-params'))
        if (localStorage.getItem('click-login-from-page-params') !== '') {
          routeOpt.params = paramsJson
        }
        this.$router.push(this.localeLocation(routeOpt))
      } else {
        this.showLoading()
        const backUrl = localStorage.getItem('back_url')
        if (backUrl) {
          this.$router.push(backUrl)
        } else {
          this.$router.push('/')
        }
      }
      // else {
      //   this.$parent.goToCheckout(false)
      // }
    }
  }
}
