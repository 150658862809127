
// import Swal from 'sweetalert2'
export default {
  data () {
    return {
      otpInputs: Array(6).fill('')
    }
  },
  // async mounted () {
  //   await this.$nextTick().then(() => {
  //     console.log('Nexttick >>> 3')
  //     setTimeout(() => {
  //       this.$refs.otpInput0[0].click()
  //       // this.$refs.otpInput0[0].focus()
  //     }, 0)
  //   })
  // },
  methods: {
    handleInput (index) {
      const value = this.otpInputs[index].replace(/[^0-9]/g, '') // กรองเฉพาะตัวเลข
      this.$set(this.otpInputs, index, value)
      if (value.length === 1) {
        this.focusNext(index)
      }
    },
    focusNext (index) {
      const nextRef = this.$refs[`otpInput${index + 1}`]
      if (nextRef) {
        this.$refs[`otpInput${index + 1}`][0].focus()
      }
    },
    // focusNext (index) {
    //   if (this.otpInputs[index].length === 1 && index < this.otpInputs.length - 1) {
    //     console.log('>>> index <<<', index)
    //     console.log('>>> otpInputs <<<', this.$refs.otpInput)
    //     // this.$refs.otpInput[index + 1].focus()
    //   }
    // },
    focusPrev (index) {
      const prevRef = this.$refs[`otpInput${index - 1}`]
      if (prevRef) {
        this.$refs[`otpInput${index - 1}`][0].focus()
      }
    },
    submitOtp () {
      const otp = this.otpInputs.join('')
      if (otp.length === 6) {
        this.$emit('confirmOTP', otp)
      }
    },
    handleBackspace (index, event) {
      if (event.key === 'Backspace') {
        // ตรวจสอบว่าค่าปัจจุบันว่างหรือไม่
        if (this.otpInputs[index] === '' && index > 0) {
          // ย้าย focus ไปที่ input ก่อนหน้า
          this.focusPrev(index)
        } else {
          // ลบค่าของ input ปัจจุบันก่อน
          this.otpInputs[index] = ''
        }
      }
    },
    handlePaste (event) {
      event.preventDefault()
      const pasteData = event.clipboardData.getData('text') // ดึงข้อมูลจาก clipboard
      console.log('pasteData', pasteData)
      // ตรวจสอบให้แน่ใจว่าคือเลขและมีความยาวเท่ากับ 6 ตัวเลข
      if (/^\d{6}$/.test(pasteData)) {
        // console.log('tetsetsets///')
        for (let i = 0; i < pasteData.length; i++) {
          console.log('i', i, pasteData[i])
          this.$set(this.otpInputs, i, pasteData[i])
          this.$refs[`otpInput${i}`][0].focus()
        }
        // console.log('otpInputs', this.otpInputs)
      }
    }
  }
}
