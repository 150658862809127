
import InputOtp from './InputOtp.vue'
export default {
  components: {
    InputOtp
  },
  methods: {
    focusFirstInput () {
      this.$nextTick(() => {
        this.$refs.refOTP.$refs.otpInput0[0].focus()
      })
    },
    async showModal () {
      await this.$bvModal.show('myModal')
    },
    hideModal () {
      this.$bvModal.hide('myModal')
    },
    confirmOTP (otp) {
      this.$emit('confirmOTP', otp)
    }
  }
}
