
import { BIconCheck2 } from 'bootstrap-vue'
import { mapMutations } from 'vuex'
import Swal from 'sweetalert2'
import CreditDebit2C2P from '~/components/PaymentMethod/CreditDebit2C2P.vue'
const cfg = require(`~/configs/${process.env.NODE_ENV}.json`)
export default {
  components: {
    CreditDebit2C2P,
    BIconCheck2
  },
  props: {
    orderID: {
      type: [String, Number],
      required: true,
      default: null
    },
    order: {
      type: Object,
      default: null,
      required: true
    },
    selectedPaymentMethod: String,
    selectedCreditType: String
  },
  data () {
    return {
      cloudfrontUrl: cfg.s3.cloudfront,
      selected: null,
      selected2c2p: false,
      csSelected: null
    }
  },
  watch: {
    selected: {
      handler (newVal) {
        if (this.selected) {
          this.$emit('update:selectedPaymentMethod', newVal)
        }
      },
      deep: true
    },
    csSelected: {
      handler (newVal) {
        if (this.csSelected) {
          this.$emit('update:selectedCreditType', newVal)
        }
      },
      deep: true
    },
    selectedPaymentMethod (newVal, oldVal) {
      if (newVal !== 'QRCS') {
        this.selected = null
      }
    }
    // csSelected: {
    //     handler (newVal) {

    //     }
    // }
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading']),
    async paid () {
      if (this.$parent.validateBeforePay() === false) {
        return
      }
      if (this.selected === 'CS-KASIKORN' && this.selected === 'CS-OTHER') {
        return
      }
      try {
        this.showLoading()
        const { error, success, order } = await this.$axios.$get(`order/${this.orderID}/paid-qrcs`)
        if (error) {
          const response = {
            error
          }
          this.$emit('error', response)
        } else if (success) {
          this.hideLoading()
          this.orderData = order
          this.$emit('paid', true)
          // this.$router.push(this.localeLocation({ name: 'my-orders-orderId', refresh: Date.now() }))
        } else {
          this.hideLoading()
          Swal.fire({
            title: 'เกิดข้อผิดพลาด',
            text: 'ไม่สามารถทำรายการได้ กรุณาลองอีกครั้ง',
            icon: 'error'
          })
        }
      } catch (errors) {
        this.hideLoading()
        if (errors.response.status === 422) {
          let errorText = ''
          Object.values(errors.response.data.errors).forEach((arr, i) => {
            arr.forEach((err, index) => {
              errorText += err
            })
          })

          Swal.fire({
            title: 'เกิดข้อผิดพลาด',
            text: errorText,
            icon: 'error'
          })
        }
      }
    },
    error (response) {
      this.$emit('error', response)
    }
  }
}
