
import { mapState, mapGetters } from 'vuex'

export default {
  data () {
    return {
      // purposes: [],
      consentSelected: []
    }
  },
  computed: {
    ...mapState(['userData', 'purposes']),
    ...mapGetters(['isAuthenticated']),
    privacyLink () {
      // const currentLang = this.$i18n.locale
      return process.env.SCGP_PRIVACY
    }
  },
  mounted () {
    // console.log('purposes', this.purposes)
    // console.log('userData', this.userData, 'isAuth', this.isAuthenticated)
    // this.getActivePurposes()
  },
  created () {
    // console.log('----> created <----')
    // this.getActivePurposes()
  },
  methods: {
    async validPhone () {
      if (this.userData.phone === null) {
        const result = await this.$axios.get('me')
        if (result.data.user) {
          this.userData.phone = result.data.user.phone
        }
      }
    },
    // async getActivePurposes () {
    //   await this.validPhone()
    //   const params = {
    //     params: {
    //       phone: this.userData.phone
    //     }
    //   }
    //   try {
    //     const result = await this.$axios.$get('scgppdpa-get-active-purposes', params)
    //     if (result) {
    //       console.log('result >>>>', result)
    //       this.purposes = result.data.purposes
    //     }
    //   } catch (errors) {
    //     if (errors.response.status === 422) {
    //       let errorText = ''
    //       Object.values(errors.response.data.errors).forEach((arr, i) => {
    //         arr.forEach((err, index) => {
    //           errorText += err
    //         })
    //       })

    //       Swal.fire({
    //         title: 'เกิดข้อผิดพลาด',
    //         text: errorText,
    //         icon: 'error'
    //       })
    //     }
    //   }
    // },
    async submitPurposes () {
      await this.validPhone()

      const params = {
        phone: this.userData.phone,
        // phone: this.userData.phone + '2',
        purposes: this.consentSelected
      }
      if (this.consentSelected.length === 0) {
        return
      }
      try {
        const result = await this.$axios.$post('scgppdpa-submit-purposes', params)
        if (result) {
          // console.log('result >>>>', result)
          this.purposes = result.data.purposes
        }
      } catch (errors) {
        console.log('error', errors.message)
        // if (errors.response.status === 422) {
        //   let errorText = ''
        //   Object.values(errors.response.data.errors).forEach((arr, i) => {
        //     arr.forEach((err, index) => {
        //       errorText += err
        //     })
        //   })

        //   Swal.fire({
        //     title: 'เกิดข้อผิดพลาด',
        //     text: errorText,
        //     icon: 'error'
        //   })
        // }
      }
    }
  }
}
