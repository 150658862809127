
import { mapMutations } from 'vuex'
import QRCode from '~/components/PaymentMethod/QRCodeCollapse.vue'
import QRCS from '~/components/PaymentMethod/QRCSCollapse.vue'
import SCB from '~/components/PaymentMethod/SCBCollapse.vue'
import emailModal from '~/components/modal/email.vue'
export default {
  components: {
    QRCode,
    QRCS,
    SCB,
    emailModal
  },
  props: {
    orderID: {
      type: [String, Number],
      required: true
    },
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isMobileDevice: false,
      selectedCreditType: null,
      selectedPaymentMethod: null,
      collapses: {
        qrcode: false,
        qrcs: false,
        scb: false
      }
    }
  },
  computed: {
  },
  watch: {
    selectedPaymentMethod (newValue, oldValue) {
      Object.keys(this.collapses).forEach((key) => {
        this.collapses[key] = false
      })
      // Set the selected collapse state to true
      this.collapses[newValue.toLowerCase()] = true
    }
  },
  mounted () {
    if (process.browser) {
      const userAgent = navigator.userAgent
      const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
      this.isMobileDevice = isMobileDevice
    }
  },
  methods: {
    ...mapMutations(['hideLoading']),
    payment (status = null) {
      this.$emit('paid', status, this.selectedPaymentMethod)
    },
    error (result, status) {
      if (result.error === 'verifyEmail') {
        this.hideLoading()
        this.$refs.emailModal.show = true
        return false
      }
    },
    goToCheckout (status) {
    },
    validateBeforePay () {
      this.$emit('submitPurposes')
      return true
      // return true
    }
  }
}
