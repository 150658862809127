
const cfg = require(`~/configs/${process.env.NODE_ENV}.json`)
export default {
  props: {
    showInOrder: {
      type: Boolean,
      required: false,
      default: false
    },
    paymentTypeId: {
      type: [Number, String],
      required: false,
      default: null
    }
  },
  data () {
    return {
      cloudfrontUrl: cfg.s3.cloudfront
    }
  },
  computed: {
    paymentTypeText () {
      switch (this.paymentTypeId) {
        case 1:
          return 'โอนเงิน'
        case 2:
          return 'บัตรเครดิต/เดบิต'
        case 3:
          return 'Online Banking'
        case 4:
          return 'ผ่อนชำระ'
        case 5:
          return 'QR Code'
        case 6:
          return 'SCB Mobile Banking'
        case 7:
          return 'บัตรเครดิต / บัตรเดบิต'
        default:
          return 'ยังไม่ได้เลือกประเภทการชำระเงิน'
      }
    }
  },
  methods: {
    calculateHeight (width) {
      // Aspect ratio of the image (assuming 16:9 aspect ratio)
      const aspectRatio = 16 / 9 // You should replace this with the actual aspect ratio of your image

      // Calculate height based on aspect ratio
      return Math.round(width / aspectRatio)
    }
  }
}
