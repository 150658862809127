
export default {
  components: {
  },
  data () {
    return {
      loadMore: false,
      cover_video: [true, true, true],
        customers: [
      {
        img: "customer/Customer1/83_1.png",
        cover_video: "customer/Customer1/SiamMotobike2.jpg",
        video: "customer/Customer1/SiamMotobike.mp4",
        text: '"อยากทำกล่องของตัวเองเพราะว่าอยากโฆษณาแบรนด์ตัวเองเป็นหลัก อยากทำแพคเกจจิ้งที่ดูพรีเมี่ยม ดูดี ดูแข็งแรง เวลาเราส่งมอบให้ลูกค้า มันเป็นความรู้สึก First Impression ที่ดีครับ"',
        avatar: "customer/Customer1/customer1.png",
        name: "คุณศิรพงศ์",
        brand: "เจ้าของแบรนด์ Siam Motobike",
      },
      {
        img: "customer/Customer2/Product Pic2.png",
        cover_video: "customer/Customer2/Nick_LocoPack2.jpg",
        video: "customer/Customer2/Nick_LocoPack.mp4",
        text: '"ทุกแบรนด์เราต้องหาดีเอ็นเอของเราให้เจอก่อน เราต้องรู้ เราต้องทราบว่าดีเอ็นเอของเราคืออะไร Character ของแบรนด์เราเป็นอย่างไร แล้วสิ่งที่ตามมา Product ก็ตาม กล่องก็ตาม ถุงก็ตาม หรือแม้แต่โลโก้ของเรา มันต้องไปในทิศทางเดียวกัน"',
        avatar: "customer/Customer2/Customer2.png",
        name: "คุณนิค",
        brand: "Founder ของ BRISUTHI",
      },
      {
        img: "customer/Customer3/Product Pic1.png",
        cover_video: "customer/Customer3/Beta_LocoPack.jpg",
        video: "customer/Customer3/Beta_LocoPack.mp4",
        text: "“เวลาเราทำProduct แล้วมันมีคุณค่าในตัว อย่างเสื้อทหารมันมีประวัติศาสตร์ เราก็อยากให้มันรู้สึกอยู่ใน Position ที่ระดับกลางหรือบน เพราะฉะนั้น Packaging ก็จะเป็นส่วนที่เสริมให้ Product เราดูอยู่ตรงนั้นจริงๆ ไม่ใช่แค่ราคา”",
        avatar: "customer/Customer3/customerPic3.png",
        name: "คุณแจ๊ค",
        brand: "Beta Company",
      }
    ]
    }
  },
  methods: {
    showCustomerVideo(index) {
      this.cover_video[index] = false;
      // this.$refs['video_customer' + index].play()
    }
  }
}
