
import { ValidationProvider } from 'vee-validate'
export default {
  components: {
    ValidationProvider
  },
  data () {
    return {
      accepted_1: false,
      accepted_2: false,
      accepted_3: false,
      consents: {
        accepted: false,
        accepted_news: false,
        accepted_personal: false
      }
    }
  },
  watch: {
    accepted_1 (newVal, oldVal) {
      if (newVal === true) {
        this.accepted_2 = true
        this.accepted_3 = true
      }
      this.consents.accepted = newVal
      // console.log('watched 1', newVal, this.consents)

      this.$emit('update:consent', newVal)
    },
    accepted_2 (newVal, oldVal) {
      this.consents.accepted_news = newVal
      // console.log('watched 2', newVal, this.consents)
    },
    accepted_3 (newVal, oldVal) {
      this.consents.accepted_personal = newVal
      // console.log('watched 3', newVal, this.consents)
    },
    consents: {
      handler (newVal) {
        this.$emit('update:consents', newVal)
        // console.log('watched 4', newVal, this.consents)
      },
      deep: true
    }
  },
  methods: {
    getValidationState ({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    }
  }
}
