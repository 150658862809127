
export default {
  props: {
    orderID: {
      type: [Number, String],
      required: true,
      default: null
    },
    paymentSelected: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      selected: null
    }
  },
  watch: {
    selected: {
      handler (newVal) {
        this.$emit('update:paymentSelected', newVal)
      },
      deep: true
    }
  },
  methods: {
    paid () {

    }
  }
}
