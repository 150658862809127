
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters, mapMutations } from 'vuex'
const cfg = require(`~/configs/${process.env.NODE_ENV}.json`)
export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    orderID: {
      type: [String, Number],
      required: true,
      default: null
    }
  },
  data () {
    return {
      apiBaseURL: cfg.axios.apiBaseURL,
      cardNumber: '',
      cardHolderName: '',
      ExpirationDateMonth: '',
      ExpirationDateYear: '',
      CVV: ''
    }
  },
  computed: {
    ...mapGetters(['getToken'])
  },
  mounted () {
    // console.log('[-] 2C2P ENV is ', process.env.NODE_ENV)
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert', 'setAlertWithLine']),
    calCostPerPcs2C2P (totalCost, amt) {
      return Math.round(((totalCost * 0.03) / amt) * 100) / 100// =ROUND((totalCost*0.03)/amt,2);
    },
    async submitCreditCard () {
      this.$refs.amount.value = await this.$formatPriceFloat(this.calCostFor2C2P(this.order))
      await window.My2c2p.getEncrypted('2c2p-payment-form', (encryptedData, errCode, errDesc) => {
        if (errCode !== 0) {
          alert(errDesc + ' (' + errCode + ')')
          this.hideLoading()
        } else {
          const form = document.getElementById('2c2p-payment-form')
          this.$refs.orderID.value = this.orderID
          this.$refs.encryptedCardInfo.value = encryptedData.encryptedCardInfo
          this.$refs.maskedCardInfo.value = encryptedData.maskedCardInfo
          this.$refs.expMonthCardInfo.value = encryptedData.expMonthCardInfo
          this.$refs.expYearCardInfo.value = encryptedData.expYearCardInfo
          this.$refs.typeCreditCard.value = this.creditCardType(this.cardNumber)
          form.submit()
        }
      })
    },
    calCostFor2C2P (order) {
      //  const totalCost = order.total
      const totalCost = order.last_total
      // const amt = order.products[0].amount
      // const sumPrice = ('sum_price' in order) ? order.sum_price : totalCost
      // const discount = ('promotion' in order && order.promotion) ? order.promotion.discount : 0
      // const costPerPcs = this.calCostPerPcs2C2P((sumPrice - discount), amt)
      // const fee = costPerPcs * amt
      // return Number(totalCost) + Number(fee) เปลี่ยนเป็นไม่หักค่าธรรมเนียมแล้ว
      return Number(totalCost)
    },
    async paid2c2p (e) {
      e.preventDefault()
      const status = await this.checkVerifyEmail()
      if (!status) {
        return
      }
      await this.$refs.form.validate().then((isValid) => {
        // console.log('isValid >>> ', isValid)
        // console.log('Validation Errors >>> ', this.$refs.form.errors)
        const invalidFieldNames = Object.keys(this.$refs.form.errors)

        // console.log('Invalid Field Names:', invalidFieldNames)

        // Optionally, log detailed errors for each field
        invalidFieldNames.forEach((fieldName) => {
        //   console.log(`${fieldName} Errors:`, this.$refs.form.errors[fieldName])
        })

        if (isValid) {
          this.submitCreditCard()
          // await this.saveInvoice()
        }
      })
    },
    async checkVerifyEmail () {
      const { error } = await this.$axios.$get(`users/verify-email/${this.orderID}`)
      if (error) {
        const response = {
          error
        }
        this.$emit('error', response)
        return false
      }
      return true
    },
    creditCardType (number) {
      // visa
      let re = new RegExp('^4')
      if (number.match(re) != null) { return 'Visa' }

      // Mastercard
      // Updated for Mastercard 2017 BINs expansion
      if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) { return 'Mastercard' }

      // AMEX
      re = new RegExp('^3[47]')
      if (number.match(re) != null) { return 'AMEX' }

      // Discover
      re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)')
      if (number.match(re) != null) { return 'Discover' }

      // Diners
      re = new RegExp('^36')
      if (number.match(re) != null) { return 'Diners' }

      // Diners - Carte Blanche
      re = new RegExp('^30[0-5]')
      if (number.match(re) != null) { return 'Diners - Carte Blanche' }

      // JCB
      re = new RegExp('^35(2[89]|[3-8][0-9])')
      if (number.match(re) != null) { return 'JCB' }

      // Visa Electron
      re = new RegExp('^(4026|417500|4508|4844|491(3|7))')
      if (number.match(re) != null) { return 'Visa Electron' }

      return ''
    }
  },
  head () {
    return {
      script: [
        {
          hid: 'My2c2p',
          // src: ((process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') ? 'https://t.2c2p.com/securepayment/api/my2c2p.1.6.9.min.js' : 'https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/api/my2c2p.1.6.9.min.js'),
          src: ((process.env.NODE_ENV === 'production') ? 'https://t.2c2p.com/securepayment/api/my2c2p.1.6.9.min.js' : 'https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/api/my2c2p.1.6.9.min.js'),
          type: 'text/javascript',
          async: true,
          defer: true
        }
      ]
    }
  }
}
