
import { BSkeletonImg, BSkeleton } from 'bootstrap-vue'
export default {
  components: {
    'b-skeleton-img': BSkeletonImg,
    'b-skeleton': BSkeleton,
  },
  data () {
    return {
        productGroupCategories: [],
        waypointLoaded: [],
    }
  },
  methods: {
    async getProductGroupCategory () {
      const productGroupCategories = await this.$axios.get('product-group-categories')
      this.productGroupCategories = productGroupCategories.data[0]
    },
    onWaypoint ({ going, direction, el }) {
      if (going === this.$waypointMap.GOING_IN && (direction === this.$waypointMap.DIRECTION_TOP || direction === this.$waypointMap.DIRECTION_BOTTOM || direction === undefined)) {
        const sectionName = el.getAttribute('data-section-name')
        if (this.waypointLoaded[sectionName] === undefined) {
          const functionName = `get${sectionName}`
          this[functionName]()
          this.waypointLoaded[sectionName] = true
        }
      }
    },
  }
}
