
import { BIconCheck2, BIconChevronDown, BIconChevronUp } from 'bootstrap-vue'
import { mapMutations } from 'vuex'
import Swal from 'sweetalert2'
const cfg = require(`~/configs/${process.env.NODE_ENV}.json`)
export default {
  components: {
    BIconCheck2,
    BIconChevronDown,
    BIconChevronUp
  },
  props: {
    orderID: {
      type: [String, Number],
      required: true
    },
    selectedPaymentMethod: String
  },
  data () {
    return {
      cloudfrontUrl: cfg.s3.cloudfront,
      selected: null,
      csSelected: null,
      collapseHowto: false
    }
  },
  watch: {
    selected: {
      handler (newVal) {
        if (this.selected) {
          this.$emit('update:selectedPaymentMethod', newVal)
        }
      },
      deep: true
    },
    selectedPaymentMethod (newVal, oldVal) {
      if (newVal !== 'SCB') {
        this.selected = null
      }
    }
  },
  created () {
    const isStaging = process.env.NODE_ENV !== 'production'
    if (isStaging) {
      // Import VConsole only in the staging environment
      import('vconsole').then(({ default: VConsole }) => {
        const vConsole = new VConsole()
        console.log('vConsole', vConsole)
      })
    }
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert', 'setAlertWithLine']),
    toggleCollapse () {
      this.collapseHowto = !this.collapseHowto
    },
    async paid () {
      if (this.$parent.validateBeforePay() === false) {
        console.log('debug scbcollapse validateBeforePay is false')
        return
      }

      try {
        this.showLoading()
        const { error, success, order } = await this.$axios.$get(
          `order/${this.orderID}/paid-debit-direct`
        )
        if (error) {
          this.hideLoading()
          const response = {
            error
          }
          this.$emit('error', response)
        } else if (success) {
          this.orderData = order
          const url = order.api_payment_response.deeplinkUrl
          this.hideLoading()
          console.log('deeeeeplink', url)
          window.open(url, '_blank')
          this.$emit('paid', true)

          // const objectRoute = this.localeLocation({ name: 'my-orders-orderId' })
          // objectRoute.params = {
          //   orderId: this.orderID
          // }
          // await this.$router.push(objectRoute)
        } else {
          this.hideLoading()
          Swal.fire({
            title: 'เกิดข้อผิดพลาด',
            text: 'ไม่สามารถทำรายการได้ กรุณาลองอีกครั้ง',
            icon: 'error'
          })
        }
      } catch (errors) {
        this.hideLoading()
        console.log('error', errors.message)
        if (errors.response.status === 422) {
          let errorText = ''
          Object.values(errors.response.data.errors).forEach((arr, i) => {
            arr.forEach((err, index) => {
              errorText += err
            })
          })

          Swal.fire({
            title: 'เกิดข้อผิดพลาด',
            text: errorText,
            icon: 'error'
          })
        }
      }
    }
  }
}
