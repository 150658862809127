
export default {
  components: {
  },
  props: { title: { type: String, default: '' }, url: { type: String, default: '' } },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    copyUrl () {
      const dummy = document.createElement('input')
      const text = window.location.href

      document.body.appendChild(dummy)
      dummy.value = text
      dummy.select()
      document.execCommand('copy')
      document.body.removeChild(dummy)
    }
  }
}
