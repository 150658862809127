
import { BSkeleton, BSkeletonImg } from 'bootstrap-vue'
export default {
  components: {
    'b-skeleton': BSkeleton,
    'b-skeleton-img': BSkeletonImg
  },
  data () {
    return {
        waypointLoaded: [],
        patternsSort: [],
    }
  },
//   async fetch() {
//     const productCategories = await this.$axios.get('product-categories')
//     this.patternsSort = productCategories
//   },
  methods: {
    getAllPackaging () {
      this.$axios.$get('product-categories').then(res => {
        this.patternsSort = res
      })
    },
    onWaypoint ({ going, direction, el }) {
      if (going === this.$waypointMap.GOING_IN && (direction === this.$waypointMap.DIRECTION_TOP || direction === this.$waypointMap.DIRECTION_BOTTOM || direction === undefined)) {
        const sectionName = el.getAttribute('data-section-name')
        if (this.waypointLoaded[sectionName] === undefined) {
          const functionName = `get${sectionName}`
          this[functionName]()
          this.waypointLoaded[sectionName] = true
        }
      }
    },
    getPatternImg(pattern) {
      return pattern.cover ? `${this.$store.state.aws_s3}${pattern.cover.path}` : `${this.$store.state.aws_s3}product_set_image_open/default-image.png`
    },
  }
}
