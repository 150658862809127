
import { BSkeletonImg } from 'bootstrap-vue'
export default {
props: {
  banners: {
    type: Array,
    default: []
  },
  promoCodePopup: {
    type: Object,
    default: {}
  }
},
components: {
  'b-skeleton-img': BSkeletonImg,
},
data () {
  return {
    slide: 0,
    sliding: false,
    bannerMinHeight: [0]
    // banners: []
  }
},
// async fetch() {
//   const banners = await this.$axios.get('banners')
//   this.banners = banners.data.banner_slide
// },
async mounted () {
  // console.log('bannerrr >>>', this.banners)
  this.$nextTick(() => { // remove role="button" pagespeed
      const indicators = document.querySelectorAll('.carousel-indicators li[role="button"]');
      indicators.forEach(indicator => {
          indicator.removeAttribute('role'); // ลบ role
      });
  });
//     const banners = await this.$axios.get('banners')
//     this.banners = banners.data.banner_slide
},
methods: {
  onSlideStart (slide) {
    this.sliding = true
  },
  onSlideEnd (slide) {
    this.sliding = false
  },
  clickOnBanner(index, bannerUrl, videoUrl) {
    const banner = this.banners[index];
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "promoClicked",
      ecommerce: {
        promoClick: {
          promotions: [
            {
              id: "",
              name: String(banner.banner_path).replace(
                /banners\/|\.jpg|\.png|\.gif/gi,
                ""
              ),
              position: index + 1,
            },
          ],
        },
      },
    });
    if (videoUrl) {
      this.videoUrlBanner = videoUrl;
      this.$bvModal.show("modal-video-banner");
    } else if (bannerUrl) {
      window.location = bannerUrl;
    }
  },
  async showModal (banner) {
    if(banner.banner_position === 'banner_slide4') {
      await this.$firebaseBannerPlugin()
      window.location.href = "https://bit.ly/3ZxXeFz"
      // window.location.href = "https://line.me/R/ti/p/@gwc6379j?oat__id=1303339"
    }
  },
  goToUrl(url) {
      console.log('clickkkk');
      if (url) {
          window.open(url, '_blank');
      }
  },
  onImageLoad(event, index) {
  const imgElement = event.target; // เข้าถึงองค์ประกอบ <img>
  const imageHeight = imgElement.naturalHeight; // รับความสูงตามธรรมชาติของรูปภาพ
  const rect = imgElement.getBoundingClientRect();
  console.log('Image loaded for index:', index);
  console.log('Loaded image natural height:', index, imageHeight, rect.height);
  // if(rect.height != 0) {
    this.bannerMinHeight[index] = 10
  // }
  imgElement.onload = () => {
    console.log('iamgeeee')
  }
  // เก็บความสูงที่ได้ลงใน banners
  // this.banners[index].height = imageHeight;
},
  // onImageLoad(index, imageUrl) {
  //   console.log('Image loaded for index:', index);
  // console.log('Loaded image URL:', imageUrl);
  // const img = new Image();
  // img.src = imageUrl;

  // img.onload = () => {
  //   console.log('Image height:', img.height); // คุณสามารถเก็บความสูงที่นี่
  //   // this.banners[index].height = img.height; // เก็บความสูงของรูปภาพที่โหลดสำเร็จ
  // };

  // img.onerror = () => {
  //   console.error('Image failed to load', index);
  //   // this.banners[index].hasError = true; // ตั้งค่า error เมื่อโหลดรูปไม่สำเร็จ
  // };
  //   },
  onImageError(index) {
    console.log('!!! img error 1!!', index)
    this.banners[index].hasError = true;
    this.banners[index].display = 'none';
    // if(this.isMobile) {
    //   this.bannerMinHeight[index] = 150
    // } else {
    //   this.bannerMinHeight[index] = 300
    // }
  }
},
computed: {
    // คอมพิวต์ฟังก์ชันเพื่อกรองแบนเนอร์
    filteredBanners() {
      return this.banners.filter(banner => banner.banner_type === 1);
    },
    isMobile() {
      if (process.client) { // ตรวจสอบให้แน่ใจว่าโค้ดทำงานในฝั่ง client
        return window.innerWidth < 768; // ปรับขนาดตามที่ต้องการ
      }
      return false; // หรือ return true ถ้าต้องการให้เป็นมือถือใน server-side rendering
    }
  }
}
