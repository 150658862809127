
import { BIconCheck2 } from 'bootstrap-vue'

import { mapMutations } from 'vuex'
import Swal from 'sweetalert2'
const cfg = require(`~/configs/${process.env.NODE_ENV}.json`)
export default {
  components: {
    BIconCheck2
  },
  props: {
    selectedPaymentMethod: {
      type: String,
      default: null
    },
    orderID: {
      type: [String, Number],
      required: true,
      default: null
    },
    imgProxy: null
  },
  data () {
    return {
      cloudfrontUrl: cfg.s3.cloudfront,
      selected: null
    }
  },
  watch: {
    selected: {
      handler (newVal) {
        if (this.selected) {
          this.$emit('update:selectedPaymentMethod', newVal)
        }
      },
      deep: true
    },
    selectedPaymentMethod (newVal, oldVal) {
      if (newVal !== 'QRCODE') {
        this.selected = null
      }
    }
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert', 'setAlertWithLine']),
    async paid () {
      if (this.$parent.validateBeforePay() === false) {
        return
      }
      try {
        this.showLoading()
        const { error, success, order, message } = await this.$axios.$get(`order/${this.orderID}/paid-qrcode`)
        // console.log('succeessss', success, order)
        if (error) {
          const response = {
            error
          }
          this.$emit('error', response)
        } else if (success) {
          //   console.log('--- QR Success ---')
          this.hideLoading()
          this.orderData = order
          // this.$router.push('/my-orders/' + this.orderID)
          // console.log('success emit paid')
          this.$emit('paid', true)
          // const objectRoute = this.localeLocation({ name: 'my-orders-orderId' })
          // objectRoute.params = {
          //   orderId: this.orderID
          // }
          // objectRoute.query = {
          //   refresh: Date.now()
          // }
          // await this.$router.push(objectRoute)
        } else {
          console.log('--- QR Not Success ---')
          this.hideLoading()
          Swal.fire({
            title: 'เกิดข้อผิดพลาด ไม่สามารถทำรายการได้ กรุณาลองอีกครั้ง',
            text: message,
            icon: 'error'
          })
        }
      } catch (errors) {
        console.log('--- QR Catch ---')
        this.hideLoading()
        if (errors.response.status === 422) {
          let errorText = ''
          Object.values(errors.response.data.errors).forEach((arr, i) => {
            arr.forEach((err, index) => {
              errorText += err
            })
          })

          Swal.fire({
            title: 'เกิดข้อผิดพลาด',
            text: errorText,
            icon: 'error'
          })
        }
      }
    }
  }
}
