
import { BSkeletonImg, BSkeleton } from 'bootstrap-vue'
export default {
  components: {
    'b-skeleton-img': BSkeletonImg,
    'b-skeleton': BSkeleton,
  },
  data () {
    return {
        articles_promotion: [],
        articles: [],
        waypointLoaded: [],
    }
  },
  methods: {
    async getArticle () {
      const articles = await this.$axios.get("articles", { params: { limit: 4, forHomepage: 1 }})
      const articles_promotion = await this.$axios.get("articles_promotion", { params: { limit: 4, forHomepage: 1 }})
      this.articles = articles.data.data
      this.articles_promotion = articles_promotion.data.data
    },
    viewArticle(itemId) {
      return { name: "articles-id", params: { id: itemId } }
    },
    onWaypoint ({ going, direction, el }) {
      if (going === this.$waypointMap.GOING_IN && (direction === this.$waypointMap.DIRECTION_TOP || direction === this.$waypointMap.DIRECTION_BOTTOM || direction === undefined)) {
        const sectionName = el.getAttribute('data-section-name')
        if (this.waypointLoaded[sectionName] === undefined) {
          const functionName = `get${sectionName}`
          this[functionName]()
          this.waypointLoaded[sectionName] = true
        }
      }
    },
  }
}
