
import Swal from 'sweetalert2'
import { mapMutations } from 'vuex'
export default {
  props: {
    id: {
      type: [Number, String],
      default: null
    },
    alignCenter: {
      type: Boolean,
      default: true
    },
    header: {
      type: Boolean,
      default: false
    }
  },
  async mounted () {
    try {
      const { data } = await this.$axios.$get(`orders/${this.id}`)
      this.order = data
      this.imageLoaded = true
    } catch (e) {
      console.log('error', e.message)
      // this.$router.push({ name: 'my-orders' })
    }
    this.detectDevice()
    window.addEventListener('resize', this.detectDevice)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.detectDevice)
  },
  data () {
    return {
      isMobileDevice: false,
      order: null
    }
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading']),
    detectDevice () {
      const userAgent = navigator.userAgent
      const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
      this.isMobileDevice = isMobileDevice
      return isMobileDevice
    },
    paid () {
      if (this.isMobileDevice) {
        const url = this.order.api_payment_response.deeplinkUrl
        // this.$emit('paid')
        window.open(url)
      } else {
        Swal.fire({
          title: 'กรุณากดชำระเงินผ่านมือถือ',
          icon: 'warning'
        })
      }
    }
  },
  computed: {
    alignContent () {
      if (this.alignCenter) {
        return 'justify-content-center'
      }
      return ''
    }
  }
}
