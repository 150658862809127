
import Swal from 'sweetalert2'
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    orderID: {
      type: [String, Number]
    }
  },
  asyncData ({ params }) {
  },
  data () {
    return {
      ccEmail: [
        ''
      ]
    }
  },
  mounted () {
    this.$axios({
      url: this.$baseurl(`order/${this.orderID}`),
      method: 'GET',
      responseType: 'json' // important
    }).then((response) => {
      if (response.status === 200 && response.data.success === true) {
        if (response.data.data.cc_email) {
          this.ccEmail = response.data.data.cc_email.split(',')
        }
      }
    })
  },
  methods: {
    addCCEmail () {
      this.ccEmail.push('')
    },
    deleteCCEmail (index) {
      this.ccEmail.splice(index, 1)
    },
    async updateCCEmail (e) {
      e.preventDefault()
      const postData = {
        cc_email: this.ccEmail
      }

      try {
        const result = await this.$axios.$post(`order/${this.orderID}/update-ccemail`, postData)
        if (result) {
          Swal.fire({
            title: 'บันทึก CC-Email สำเร็จ',
            icon: 'success'
          })
        }
      } catch (errors) {
        if (errors.response.status === 422) {
          let errorText = ''
          Object.values(errors.response.data.errors).forEach((arr, i) => {
            arr.forEach((err, index) => {
              errorText += err
            })
          })

          Swal.fire({
            title: 'เกิดข้อผิดพลาด',
            text: errorText,
            icon: 'error'
          })
        }
      }
      //   if (result.success === true) {
      //     this.btnSaveAddress = true
      //     this.alertMsg = result.message
      //     if (showALert) {
      //       this.$bvModal.show('modal-show-alert')
      //     }
      //   }
    }
  }
}
