
import { BSkeletonImg, BSkeleton } from 'bootstrap-vue'
export default {
  props: {
    sampleProductCategories: {
      type: Array,
      default: []
    },
  },
  components: {
    'b-skeleton-img': BSkeletonImg,
    'b-skeleton': BSkeleton
  },
  data () {
    return {
      // sampleProductCategories: []
    }
  },
  mounted () {
    // this.$nextTick(() => {
    //     const prevButton = document.querySelector('.carousel-control-prev');
    //     const nextButton = document.querySelector('.carousel-control-next');

    //     if (prevButton) {
    //         prevButton.removeAttribute('aria-controls');
    //     }
    //     if (nextButton) {
    //         nextButton.removeAttribute('aria-controls');
    //     }
    // });
  },
  // async fetch() {
  //   const sampleProductCategories = await this.$axios.get('sample_product_categories')
  //   this.sampleProductCategories = sampleProductCategories.data.data
  // },
  methods: {
    slideLeft (el, width) {
      const content = document.querySelector(el)
      const translate = 0
      content.scrollLeft -= width
      content.style.transform = 'translateX(' + translate + 'px' + ')'
    },
    slideRight (el, width) {
      const content = document.querySelector(el)
      const translate = 0
      content.scrollLeft += width
      content.style.transform = 'translateX(' + translate + 'px' + ')'
    },
  }
}
